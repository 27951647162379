<script>
import axios from "axios";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import { authHeader } from "../../../helpers/auth-header";

import FilterModal from "@/components/modals/filter-modal.vue";
import ConfirmationModal from "@/components/modals/confirmation-modal";
import MessageModal from "@/components/modals/message-modal";

import InputGroup from "@/components/forms/input-group";
import SelectGroup from "@/components/forms/select-group";

import ListingTable from "@/components/listings/listing-table";

export default {
    components: {
        Layout,
        PageHeader,
        FilterModal,
        ConfirmationModal,
        MessageModal,
        InputGroup,
		SelectGroup,
        ListingTable,
    },
    data() {
        return {
            title: "Assignments",
            items: [
                {
                    text: "List"
                },
            ],
            filter: {
                subject_name: '',
				requestor_name: '',
				created_at: '',
				assignment_status: 'all'
            },
            fields: [
                { key: 'id', sortable: true, label: 'ID' },
                { key: 'subject_name', sortable: true, label: 'Subject/Service' },
                { key: "requestor_name", sortable: true, label: "Requestor" },
                { key: "assigned_tutor_email", sortable: true, label: 'Assigned Tutor' },
                { key: "formatted_created_at", sortable: true, label: 'Request date' },
                { key: "formatted_assigned_date", sortable: true, label: 'Assigned date' },
                { key: "num_of_applicant", label: 'Number of Applicants' },
                { key: "status", label: "Status" },
                { key: "action" },
            ],
            statusOptions: [
                { name: 'All', value: 'all' },
                { name: 'Open', value: 'open' },
                { name: 'Closed', value: 'closed' },
            ],
            isModalVisible: false,
        };
    },
    methods: {
        deleteRecord(id) {
            const deleteCallback = async () => {
                await axios.delete(`${process.env.VUE_APP_APIURL}/assignment/disable/${id}`, {
                    headers: authHeader(),
                });
                this.filterTable(this.filter);
                this.$refs.confirmationModal.closeModal();
                this.$refs.messageModal.showModal('The record has been disabled');
            }
            this.$refs.confirmationModal.showModal(`Confirm disable assignment (ID: ${id})?`, deleteCallback);
        },

        enableRecord(id) {
            const deleteCallback = async () => {
                await axios.delete(`${process.env.VUE_APP_APIURL}/assignment/enable/${id}`, {
                    headers: authHeader(),
                });
                this.filterTable(this.filter);
                this.$refs.confirmationModal.closeModal();
                this.$refs.messageModal.showModal('The record has been enabled');
            }
            this.$refs.confirmationModal.showModal(`Confirm enable assignment (ID: ${id})?`, deleteCallback);
        },

        setAdminView(filter) {
            this.filter = Object.assign(this.filter, filter);
        },

        filterTable(filters) {
			const filter = JSON.parse(JSON.stringify(filters));
			this.$refs.listingTable.filterTable(filter);
		},
		resetTable() {
			this.filter = {
				subject_name: '',
				requestor_name: '',
				created_at: '',
				assignment_status: 'all'
			};
			this.$refs.listingTable.filterTable(this.filter);
		},
		showFilterModal() {
			this.$refs.filterModal.showModal();
		},
		hideModal() {
			this.isModalVisible = false;
		},
		handleSubmit() {
			this.filterTable(this.filter);
			this.$refs.filterModal.closeModal();
		},
        copyToClipboard(id) {
            navigator.clipboard.writeText(`${process.env.VUE_APP_FRONTENDURL}/assignment/${id}`);
            
            this.$bvToast.toast('Link copied to clipboard', {
                title: 'Message',
                variant: 'primary',
                autoHideDelay: 2000,
                appendToast: true
            })
        },

        async pushTelegram(id) {
            const sendTelegramCallback = async () => {
                const res = await axios.get(`${process.env.VUE_APP_APIURL}/push/telegram/${id}`, {
                    headers: authHeader(),
                });

                this.$refs.confirmationModal.closeModal();
                if (res.data.code === 'success') {
                    this.$bvToast.toast('Assignment details pushed to Telegram', {
                        title: 'Message',
                        variant: 'primary',
                        autoHideDelay: 2000,
                        appendToast: true
                    });
                } else if (res.data.code === 'fail_to_send') {
                    this.$bvToast.toast('Fail to push message, please try again later', {
                        title: 'Warning',
                        variant: 'danger',
                        autoHideDelay: 4000,
                        appendToast: true
                    });
                } else if (res.data.code === 'invalid_telegram_channel_id') {
                    this.$bvToast.toast('Make sure the assignment is assigned a Telegram Channel', {
                        title: 'Warning',
                        variant: 'warning',
                        autoHideDelay: 4000,
                        appendToast: true
                    });
                }
            }
            this.$refs.confirmationModal.showModal(`Confirm to push to Telegram?`, sendTelegramCallback);
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <ConfirmationModal ref="confirmationModal" />
        <MessageModal ref="messageModal" />

        <FilterModal ref="filterModal" :isVisible="isModalVisible" @close="hideModal">
			<form @submit.prevent="handleSubmit">
				<div class="row">
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <InputGroup v-model="filter.subject_name" type="text" id="subject_name" displayName="Subject / Service" />
                        </div>
                    </div>

					<div class="col-12 col-md-6">
                        <div class="form-group">
                            <InputGroup v-model="filter.requestor_name" type="text" id="requestor_name" displayName="Requestor" />
                        </div>
                    </div>

                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <SelectGroup v-model="filter.assignment_status" id="status" displayName="Status" :options="statusOptions" />
                        </div>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="form-group">
                            <InputGroup v-model="filter.created_at" type="date" id="created_at" displayName="Request date" />
                        </div>
                    </div>
				</div>

				<div class="modal-footer">
                    <button type="button" class="clear__btn" @click="resetTable">Clear</button>
                    <button type="submit" class="save__btn">Filter</button>
                </div>
			</form>
		</FilterModal>

        <div class="row">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body pt-0" style="position: relative;">
                        <div class="filter_buttons">
							<button class="filter_button" @click="showFilterModal">
                                <i class="mdi mdi-filter-variant mr-2 font-size-17"></i>
                                Filter
                            </button>
						</div>
                        <ListingTable ref="listingTable" :fields="fields" path="active_assignment?filter[status]=1" listName="assignments">
                            <template v-slot:cell(status)="row">
                                <div :class="{
                                    'status-blue': `${row.value}` === 'Closed',
                                    'status-green': `${row.value}` === 'Open',
                                }">{{ row.value }}</div>
                            </template>
                            <template v-slot:cell(action)="{ item }">
                                <router-link class="mr-2 text-primary" :to="{name: 'Assignment Form', params: {id: item.id}}">
                                    <i class="mdi mdi-eye font-size-18" v-b-tooltip.hover title="View"></i>
                                </router-link>
                                <a v-if="item.status === 'Open'" href="javascript:void(0);" class="text-danger" v-b-tooltip.hover title="Disable"
                                    @click="deleteRecord(item.id)">
                                    <i class="mdi mdi-close-circle-outline font-size-18"></i>
                                </a>
                                <a v-else href="javascript:void(0);" class="text-success" v-b-tooltip.hover title="Enable"
                                    @click="enableRecord(item.id)">
                                    <i class="mdi mdi-check-circle-outline font-size-18"></i>
                                </a>
                                <a href="javascript:void(0);" class="ml-2 text-primary" v-b-tooltip.hover title="Copy Link"
                                    @click="copyToClipboard(item.id)">
                                    <i class="mdi mdi-content-copy font-size-18"></i>
                                </a>
                                <a href="javascript:void(0);" class="ml-2 text-primary" v-b-tooltip.hover title="Push to Telegram"
                                    @click="pushTelegram(item.id)">
                                    <i class="mdi mdi-telegram font-size-18"></i>
                                </a>
                            </template>
                        </ListingTable>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>

<style scoped>
.modal-footer {
    padding-top: 24px;
    padding-left: 0;
    padding-right: 0;
    margin-top: 48px;
    justify-content: space-between;
    border-top: 1px solid rgba(205, 205, 205, 0.5);

    & .clear__btn {
        border: 0;
        background-color: transparent;
        font-family: Nunito;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #505D69;
        text-decoration: underline;
    }

    & .save__btn {
        border: 1px solid #004AAD;
        border-radius: 6px;
        background: #004AAD;
        padding: 9px 20px;
        font-family: Nunito;
        font-weight: 600;
        font-size: 14px;
        color: #fff;
    }
}
</style>